import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "profile"
    }}>{`Profile`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchprofile"
    }}>{`fetchProfile`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <p>{`No specific params.`}</p>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* profile -> Profile{} = a profile object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchProfile } from '@kineticdata/react';

fetchProfile().then(({ profile }) => console.log(profile));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updateprofile"
    }}>{`updateProfile`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* profile -> Profile{} = (required) the updated profile data to send
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* profile -> Profile{} = the updated profile object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateProfile } from '@kineticdata/react';

updateProfile({
  profile: profileWithChanges,
}).then(({ profile }) => console.log(profile));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      